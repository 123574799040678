import { FaFilePdf } from "react-icons/fa";
//import {mainProductsData} from "../../utils/mainProductsData";
import React, { useEffect, useState } from "react";
import { CATEGORY_URL, PDF_URL,SEND_EMAIL, PRODUCT_URL, PRODUCT_DTL_URL } from "../../utils/constants";
import axios from "axios";
import GetOtp from "../../components/products/GetOtp";
import { jwtDecode } from "jwt-decode";
import { REGISTER_URL } from "../../utils/constants";
import { LOGIN_URL } from "../../utils/constants";
import { useLocation } from 'react-router-dom';
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css"; // Ensure you import the styles

export const Ptdlist = ({ FullGrade, Chemistry, Description, pdf_path, _id, setOpen,setProductName, setPdfLink  }) => {
  const handleRowClick = () => {
    setProductName(FullGrade);
    setPdfLink(pdf_path);
    setOpen(true); // Open the modal
  };
  return (
    <>
      <tr>
        {/* <td width="4%">
          <input
            className="form-check-input"
            type="checkbox"
            id="checkboxNoLabel"
            value=""
            newid={_id}
          />
        </td> */}
        <th width="24%">{FullGrade} <span className="d-none">{Chemistry}</span></th>
        <th width="70%">{Description}</th>
        <th className="text-end ptd-btn">
          {/* <span
            className="table-link badge text-bg-dark pointer"
            // onClick={() => setOpen(true)}
            onClick={handleRowClick}
          >
            <FaFilePdf /> Download
          </span> */}
          {pdf_path && (
            <span
              className="table-link badge text-bg-dark pointer"
              onClick={handleRowClick}
            >
              <FaFilePdf /> Download
            </span>
          )}
        </th>
        <td width="0">
          {/* <span
            className="table-link badge text-bg-dark pointer"
            data-bs-toggle="modal"
            data-bs-target={`#exampleModal${_id}`}
          >
            View 
          </span> */}
          <div
            className="modal fade product-modal"
            id={`exampleModal${_id}`}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    {FullGrade}
                  </h1>
                  <button
                    type="button"
                    className="btn-close rounded-circle"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">{Description}</div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      
    </>
  );
};

const ProductsList = () => {
  const [mainProductsData, setmainProductsData] = useState([]);
  const [categoryProductsData, setcategoryProductsData] = useState([]);
  const [mainChemistryData, setmainChemistryData] = useState([]);
  const [categoryName, setcategoryName] = useState("All");
  const [categoryData, setcategoryData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [chemistryData, setchemistryData] = useState([]);
  const [checkedCheckboxIds, setCheckedCheckboxIds] = useState([]);
  const [open, setOpen] = React.useState(false);

  // get otp

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loginformErrors, setLoginFormErrors] = useState({});
  const [productName, setProductName] = React.useState("");
  const [pdfLink, setPdfLink] = React.useState("");
  const [formMsg, setFormMsg] = React.useState("");

  //EMail
  const location = useLocation();
  useEffect(() => {
    // Scroll to top when the location (route) changes
    window.scrollTo(0, 0);
  }, [location]);
  const queryParams = new URLSearchParams(location.search);
  const paramTitle = queryParams.get('title');

  let chemistrylist = [];
   useEffect(() => {
    checkLoginStatus();
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logoutUser, 5 * 60 * 1000); // 5 minutes
    };

    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Set the initial timer
    resetTimer();

    return () => {
      clearTimeout(timer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, []);

  useEffect(() => {
    if (open) {
      setFormData({ 
        ...formData, 
        productName: productName, 
        pdfLink: pdfLink 
      });
    }
  }, [open, productName, pdfLink]); 



  const fetchData = async () => {
    try {
      const productdata = await axios.get(PRODUCT_URL);
      const product = productdata.data;
      setmainProductsData(product);

      setFilteredProducts(product);

      chemistrylist = [];
      product.forEach((element) => {
        if (element.Chemistry != '' && element.Chemistry != ' ') {
          if (chemistrylist.indexOf(element.Chemistry) === -1) {
            chemistrylist.push(element.Chemistry);
          }
        }
      });
      setchemistryData(chemistrylist);
      setmainChemistryData(chemistrylist);

      const category = await axios.get(CATEGORY_URL);
      setcategoryData(category.data);
      // if(paramTitle){
      //   setSelectedCategory(paramTitle); 
      //   filterProducts(paramTitle)
      //   console.log('test :', paramTitle)
      // }
      /* as per tridip this is not required
            setSelectedCategory(category.data[0]);
            //filterProducts(category.data[0]);
            chemistrylist=[];
            const filteredData=[];
            console.log('In fetchData');
            console.log(mainProductsData);
            // this has been added because mainProductsData not getting updated
            product.forEach(element => {
                if (element.Category===category.data[0])
                {
                    filteredData.push(element);
                    if(chemistrylist.indexOf(element.Chemistry) === -1)
                    {
                        chemistrylist.push(element.Chemistry);
                    }
                }
            });
            await setFilteredProducts(filteredData);
            // this has been added because mainProductsData not getting updated
            */
    } catch (error) {
      console.error("Error in fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();   
  }, []);

  const filterProducts = async (categoryName) => {
    // console.log("filter product name : ", categoryName);
    const filteredData = [];
    chemistrylist = [];

    //console.log(mainProductsData);
    mainProductsData.forEach((element) => {
      // console.log('a');
      if (element.Category === categoryName) {
        filteredData.push(element);
        if (element.Chemistry != '' && element.Chemistry != ' ') {
          if (chemistrylist.indexOf(element.Chemistry) === -1) {
            chemistrylist.push(element.Chemistry);
            setchemistryData(chemistrylist);
          }
        }
        setcategoryName(categoryName);
      }
    });   

     if (filteredData.length === 0) {
      setFilteredProducts(mainProductsData);  // Show all products
      setSelectedCategory("All");
      setcategoryName("All");
      setchemistryData(mainChemistryData);
    } else {
      setFilteredProducts(filteredData); // Show filtered products
      setSelectedCategory(categoryName);  // Set selected category
    }
    // setcategoryProductsData(filteredData); // Store filtered products for category
    // setchemistryData(chemistrylist);  // Update chemistry data
  };
  useEffect(() => {
    // Only filter products if data is available and paramTitle is present
    if (mainProductsData.length > 0 && paramTitle) {
      filterProducts(paramTitle);
    }
  }, [mainProductsData, paramTitle]);  // Runs when mainProductsData or paramTitle changes

  const filterByChemistry = async (chemistryName) => {
    const filteredData = [];
    console.log("categoryName", categoryName, "check");
    if (chemistryName !== " ") {
      //categoryProductsData.forEach(element => {
      mainProductsData.forEach((element) => {
        if (chemistryName != '' && categoryName != 'All') {
          if (element.Chemistry === chemistryName && element.Category === categoryName) {
            filteredData.push(element);
          }
        }
        else {
          if (categoryName == 'All' && chemistryName != '') {
            if (element.Chemistry === chemistryName) {
              filteredData.push(element);
            }
          }
          else if (categoryName == 'All' && chemistryName == '') {
            filteredData.push(element);
          }
          if (element.Category === categoryName) {
            filteredData.push(element);
          }
        }

      });
      await setFilteredProducts(filteredData);
    } else {
      // setFilteredProducts(categoryProductsData);
      setFilteredProducts(mainProductsData);
    }
  };


  const filterProductsNew = async (searchText) => {
    if (searchText !== "") {
      const filteredData = mainProductsData.filter((element) => {
        return (
          element.Description.toUpperCase().includes(searchText.toUpperCase()) ||
          element.FullGrade.toUpperCase().includes(searchText.toUpperCase())
        );
      });
      await setFilteredProducts(filteredData);
    } else {
      setFilteredProducts(mainProductsData);
    }
  };


    const filterByDesc = async (desc) => {
      const filteredData = [];
      if (desc !== "") {
        // categoryProductsData.forEach(element => {
        mainProductsData.forEach((element) => {
          if (
            element.Description.toUpperCase().indexOf(desc.toUpperCase()) != -1
          ) {
            filteredData.push(element);
          }
        });
        await setFilteredProducts(filteredData);
      } else {
        setFilteredProducts(mainProductsData);
      }
    };

    const filterByProduct = async (FullGrade) => {
      const filteredData = [];
      if (FullGrade !== "") {
        // categoryProductsData.forEach(element => {
        mainProductsData.forEach((element) => {
          if (
            element.FullGrade.toUpperCase().indexOf(FullGrade.toUpperCase()) != -1
          ) {
            filteredData.push(element);
          }
        });
        await setFilteredProducts(filteredData);
      } else {
        setFilteredProducts(mainProductsData);
      }
    };

  const selectAllCheckboxes = (event) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const selectAllChecked = event.target.checked;

    checkboxes.forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
    });
  };

  const handleAnchorClick = async () => {
    const checkedIds = [];
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      /*if (checkbox.checked) {
        checkedIds.push(checkbox.getAttribute("newid"));
      }*/
      const newid = checkbox.getAttribute("newid");
      if (checkbox.checked && newid) {
        checkedIds.push(newid);
      }

    });
    setCheckedCheckboxIds(checkedIds);
    console.log("Checked checkbox IDs:", checkedIds);
    // Create a link and download the PDF files

    for (const id of checkedIds) {

      const productdata = await axios.get(`${PRODUCT_URL}/id/${id}`);
      const product_pdf_url = productdata.data[0].pdf_path; // Assuming pdf_name is the column you need
      console.log("product", product_pdf_url);


      if (product_pdf_url !== '') {

        //const pdfUrl = `${PDF_URL}/`.product_pdf_url};
        const pdfUrl = `${PDF_URL}/tds${product_pdf_url}`;
        console.log(`Checking PDF existence at URL: ${pdfUrl}`);

        try {
          // Check if the PDF exists by sending a HEAD request
          const response = await fetch(pdfUrl, { method: "HEAD" });

          if (response.ok) {
            console.log(`PDF exists at URL: ${pdfUrl}`);

            // Fetch the PDF
            const pdfResponse = await fetch(pdfUrl);
            if (pdfResponse.ok) {
              const blob = await pdfResponse.blob();
              const url = window.URL.createObjectURL(blob);

              // Create a link element, set its href and download attribute, and click it
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${id}_pdf.pdf`);
              document.body.appendChild(link);
              link.click();

              // Clean up the DOM and the URL object
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
            } else {
              console.error(
                "Error fetching PDF:",
                pdfResponse.status,
                pdfResponse.statusText
              );
            }
          } else {
            alert("PDF does not exist at:", pdfUrl);
          }
        } catch (error) {
          console.error("Error checking PDF existence:", error);
        }
      } else {
        console.log('product_pdf_url is empty. Skipping PDF download.');
      }

    }


  };

  // get otp

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    }

    if (!formData.companyName.trim()) {
      errors.companyName = "Company name is required";
      isValid = false;
    }

    if (!formData.location.trim()) {
      errors.location = "Location is required";
      isValid = false;
    }

    // if (!formData.email.trim()) {
    //   errors.email = "Email is required";
    //   isValid = false;
    // } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   errors.email = "Email address is invalid";
    //   isValid = false;
    // }

    if (!formData.mobile_no.trim()) {
      errors.mobile_no = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile_no)) {
      errors.mobile_no = "Mobile number must be 10 digits";
      isValid = false;
    }

    // if (!formData.password) {
    //   errors.password = "Password is required";
    //   isValid = false;
    // } else if (formData.password.length < 6) {
    //   errors.password = "Password must be at least 6 characters long";
    //   isValid = false;
    // }

    /*if (formData.password !== formData.confirmPassword) {
          errors.confirmPassword = "Passwords do not match";
          isValid = false;
        }*/

    setFormErrors(errors);
    return isValid;
  };

  const validateLoginForm = () => {
    let loginerrors = {};
    let isValid = true;
    if (!loginFormData.username.trim()) {
      loginerrors.username = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(loginFormData.username)) {
      loginerrors.username = "Email address is invalid";
      isValid = false;
    }
    // if (!loginFormData.pwd) {
    //   loginerrors.pwd = "Password is required";
    //   isValid = false;
    // }
    setLoginFormErrors(loginerrors);
    return isValid;
  };

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    companyName: "",
    // location: "",
    mobile_no: "",
    email: "",
    remarks: "",
    // password: "",
    // username: "",
    // pwd: "",
    productName : productName,
    pdfLink : pdfLink
  });

  const [loginFormData, setloginFormData] = useState({
    username: "",
    pwd: "",
  });

  const handleChange = (e) => {
    console.log(e.target.value)
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleLoginChange = (e) => {
    setloginFormData({ ...loginFormData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    if (!formData || Object.keys(formData).length === 0) {
        setFormMsg("Form data is missing.");
        return false;
    }

    // Find the first empty field in formData
    const emptyField = Object.keys(formData).find(
        (field) => !formData[field] // Checks if the field is empty
    );
    if (emptyField) {
        // Capitalize field name for a user-friendly error message
        setFormMsg(`${emptyField.charAt(0).toUpperCase() + emptyField.slice(1)} is required`);
        return false;
    }

    if (validateForm()) {
        const posturl = REGISTER_URL;
        try {
            const response = await axios.post(posturl, { formData });
            if (!response.data) throw new Error("Invalid response from server");
            
            const { token, ...user } = response.data;

            // Save the JWT token and user data to local storage
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            checkLoginStatus();

            // Send email
            const emailBody = `<!doctype html>
                <html>
                <head>
                    <meta charset="utf-8">
                    <title>Untitled Document</title>
                </head>
                <body>
                    <p>Dear ${formData.firstName || ''} ${formData.lastName || ''},</p>
                    <p>Thank you for submitting the form. Please click the attached link below to access the requested TDS.</p>
                    <p>If you encounter any issues accessing the file or need further assistance, feel free to contact us at contact@mechemco.com.</p>
                    <p>We appreciate your prompt attention and continued cooperation.</p>
                    <p>Best regards,<br>
                        Mechemco Team<br><br>
                        <img src="https://www.mechemco.com/static/media/mechemco-logo.e337b4cd7b973c1204a5.png" width="200">
                    </p>
                </body>
                </html>`;

            try {
                const isAttach = formData.pdfLink ? 'Y' : 'N';
                const pdfUrl = formData.pdfLink ? `${PDF_URL}/tds${encodeURIComponent(formData.pdfLink)}` : '';
                const emailResponse = await axios.post(SEND_EMAIL, {
                    to: formData.email,
                    cc: 'contact@mechemco.com',
                    subject: 'Download your TDS PDF',
                    body: emailBody,
                    isAttachment: isAttach,
                    pdfName: pdfUrl,
                });

                if (emailResponse.data && emailResponse.data.success) {
                    // Show success message and alert
                    setFormMsg(`<p>We have sent a link to your email address to download the TDS file. Please check your inbox and follow the link to access the document. If you encounter any issues, feel free to reach out to us at <a href="mailto:contact@mechemco.com">contact@mechemco.com</a>.</p>`);
                    alert('Email sent successfully!');
                } else {
                    alert('Failed to send email.');
                }
            } catch (error) {
                console.error('Error sending email:', error);
                alert('An error occurred while sending the email.');
            }
        } catch (error) {
            console.error("error", error.response?.data?.message || error.message);
            setFormErrors({ general_error: error.response?.data?.message || "An unexpected error occurred." });
        }

        setOpen(false); // Close the modal
        setFormMsg("");
    } else {
        console.log("Form validation failed");
    }
};


  const handleloginSubmit = async (e) => {
    e.preventDefault();
    if (validateLoginForm()) {
      const posturl = LOGIN_URL;
      console.log(loginFormData);
      try {
        const response = await axios.post(posturl, { loginFormData });
        console.log(response.data);
        const { accessToken, ...user } = response.data;

        // Save the JWT token and user data to local storage
        localStorage.setItem("token", accessToken);
        localStorage.setItem("user", JSON.stringify(user));
        checkLoginStatus();
        setOpen(false); // Close the modal
      } catch (error) {
        console.error(error);
        let loginValidationerrors = {};
        loginValidationerrors.general = error.response.data;

        setLoginFormErrors(loginValidationerrors);
        console.log(loginformErrors);
      }
    }
    else {
      console.log("Form validation failed");
    }


  };

  const isTokenValid = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    // Redirect to login page or home page
    window.location.href = "/"; // Adjust the path as needed
  };

  useEffect(() => {
    checkLoginStatus();
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logoutUser, 2 * 60 * 1000); // 5 minutes
    };

    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Set the initial timer
    resetTimer();

    return () => {
      clearTimeout(timer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, []);

  // Function to check login status
  const checkLoginStatus = () => {
    if (isTokenValid()) {
      setIsLoggedIn(true);
      // console.log("User is logged in");
    } else {
      setIsLoggedIn(false);
      // console.log("User is not logged in or token has expired");
    }
  };

  // Function to toggle the visibility
  const toggleDivs = () => {
    setIsFirstDivVisible(!isFirstDivVisible);
  };

  // responsive modal start

  const sendDataTopParent = () => {
    // closeMoadal(setOpen(false))
  };
  const handleCloseModal = () => {
    setOpen(false);
    setProductName(""); // Clear modal data when modal is closed
    setPdfLink("");
    setFormMsg("");
  };


  return (
    <>
      <section className="product-list-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="product-main">
                <div className="row g-5">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                    <div className="card product-card category-name-card">
                      <div className="card-body">
                        <ul className="list-group list-group-flush">
                          <li
                            key="All"
                            className={`list-group-item ${selectedCategory === "All" ? "active" : ""
                              }`}
                          >
                            <button
                              onClick={() => {
                                setSelectedCategory("All");
                                setcategoryName("All");
                                setFilteredProducts(mainProductsData);
                                setchemistryData(mainChemistryData);
                              }}
                            >
                              All Categories
                            </button>
                          </li>
                          {
                            // mainProductsData.map(product => (
                            //     <li key={category.info.id} className={`list-group-item ${category.info.cat_name === selectedCategory ? "active" : ""}`}>
                            //         <button onClick={() => { setSelectedCategory(category.info.cat_name); filterProducts(category.info.cat_name); }}>
                            //             {category.info.cat_name}
                            //         </button>
                            //     </li>
                            // ))
                            categoryData.map((category) => (
                              <li
                                key={category}
                                className={`list-group-item ${category === selectedCategory ? "active" : ""
                                  }`}
                              >
                                <button
                                  onClick={() => {
                                    setSelectedCategory(category);
                                    filterProducts(category);
                                  }}
                                >
                                  {category}
                                </button>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                    <div className="card product-card filter-card">
                      <div className="card-body">
                        <form className="row">
                          <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12 col-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="Filter_by_Chemistry"
                              >
                                Filter by Chemistry
                              </label>
                              <select
                                name=""
                                id="Filter_by_Chemistry"
                                className="form-select"
                                onChange={(e) => {
                                  filterByChemistry(e.target.value);
                                }}
                              >
                                <option value="" defaultValue>
                                  Select
                                </option>
                                {chemistryData.map((chemistry) => (
                                  <option  key={chemistry}  value={chemistry}>{chemistry}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12 col-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="Filter_by_Chemistry"
                              >
                                Search Keyword
                              </label>
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Search here..."
                                className="form-control"
                                onChange={(e) => filterProductsNew(e.target.value)}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card product-card product-list-card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck1"
                                  onChange={selectAllCheckboxes}

                                />
                                <label
                                  className="form-check-label ms-3"
                                  htmlFor="defaultCheck1"
                                >
                                  Select all
                                </label> */}
                                Product
                                </th>
                                <th>Description</th>
                                <th  className="text-start ptd-btn">
                                  {isLoggedIn ? (
                                    <>
                                      {/*<a href="#" className="btn btn-dark" >TDS <FaFilePdf /></a>

                                                    <a href="#" className="btn btn-dark">MSDS <FaFilePdf /></a>*/}
                                                    Action
                                      <a
                                        href="#"
                                        className="btn btn-dark d-none"
                                        onClick={handleAnchorClick}
                                      >
                                        {" "}
                                        {/* Download PDF <FaFilePdf /> */}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {/*<a href="#" className="btn btn-dark" TDS></a> <FaFilePdf /></a>
                                                                    <a href="#" className="btn btn-dark">MSDS <FaFilePdf /></a>*/}
                                      Action
                                      {/* <a
                                        className="btn btn-dark"
                                        onClick={() => setOpen(true)}
                                      >
                                        {" "}
                                        <FaFilePdf /> DOWNLOAD TDSs{" "}
                                      </a> */}
                                    </>
                                  )}
                                </th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredProducts.map((product) => (
                                <Ptdlist key={product._id} {...product} setOpen={setOpen}  setProductName={setProductName} setPdfLink={setPdfLink}/>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* get otp code */}
      <Modal 
      //open={open} onClose={() => setOpen(false)}
      open={open}
        productName={productName}  // Pass the extra data to the modal
        pdfLink={pdfLink}
        onClose={handleCloseModal}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {" "}
                {isFirstDivVisible ? "Login" : "Fill this form to download"}
              </h1>
            </div>
            <div className="modal-body">
              {/* OTP FORM Starts */}
              {isFirstDivVisible && (
                <form onSubmit={handleloginSubmit}>
                  {loginformErrors.general && (
                    <div className="text-danger">
                      {loginformErrors.general}
                    </div>
                  )}

                  <div className="card career-form-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-20">
                            <label htmlFor="username" className="form-label">
                              Enter Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              value={loginFormData.username}
                              onChange={handleLoginChange}
                            />
                            {loginformErrors.username && (
                              <div className="text-danger">
                                {loginformErrors.username}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-20">
                            <label htmlFor="pwd" className="form-label">
                              Enter Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="pwd"
                              value={loginFormData.otpNo}
                              onChange={handleLoginChange}
                            />
                            {loginformErrors.pwd && (
                              <div className="text-danger">
                                {loginformErrors.pwd}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button className="btn btn-md btn-dark" type="submit">
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {/* <div className="mt-15 mb-15">
                <a
                  href="#"
                  className="text-info"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDivs();
                  }}
                >
                  {isFirstDivVisible ? "If not Registered? Sign Up" : "Login"}
                </a>
              </div> */}

              {/* Signup form start */}
              {!isFirstDivVisible && (
                <div className="card career-form-card">
                  <div className="card-body">
                    {formErrors.general_error && (
                      <div className="text-danger">
                        {formErrors.general_error}
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="mb-20">
                            <input type="hidden"  value={productName} id="productName"/>
                            <input type="hidden"  value={pdfLink} id="pdfLink"/>
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                            />
                            {formErrors.firstName && (
                              <div className="text-danger">
                                {formErrors.firstName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-20">
                            <label htmlFor="lastName" className="form-label">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                            />
                            {formErrors.lastName && (
                              <div className="text-danger">
                                {formErrors.lastName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-20">
                            <label htmlFor="companyName" className="form-label">
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                            {formErrors.companyName && (
                              <div className="text-danger">
                                {formErrors.companyName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-20">
                            <label htmlFor="location" className="form-label">
                              Location
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="location"
                              value={formData.location}
                              onChange={handleChange}
                            />
                            {formErrors.location && (
                              <div className="text-danger">
                                {formErrors.location}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-20">
                            <label htmlFor="mobile_no" className="form-label">
                              Mobile No.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="mobile_no"
                              value={formData.mobile_no}
                              onChange={handleChange}
                            />
                            {formErrors.mobile_no && (
                              <div className="text-danger">
                                {formErrors.mobile_no}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-20">
                            <label htmlFor="email" className="form-label">
                              Email Id
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {formErrors.email && (
                              <div className="text-danger">
                                {formErrors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12 d-none">
                          <div className="mb-20">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                            {formErrors.password && (
                              <div className="text-danger">
                                {formErrors.password}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-20">
                            <label htmlFor="remarks" className="form-label">
                              Remarks
                            </label>
                            <textarea
                              rows="1"
                              className="form-control"
                              id="remarks"
                              value={formData.remarks}
                              onChange={handleChange}
                            />
                            {formErrors.remarks && (
                              <div className="text-danger">
                                {formErrors.remarks}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <button className="btn btn-md btn-dark" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 mt-10">
                        {formMsg}
                        </div>
                      </div>
                        
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductsList;
