import React from 'react'

const AboutContentFull = () => {
  return (
    <section class="sub-section">
    <div className="container">
        <div className="row g-0">
            <div className="col-xl-12 col-lg-5 col-md-12 col-sm-12 col-12">
               
                       
                        <p>Mechemco is a technology driven group with a mission of developing and implementing new technologies to facilitate rapid growth of the Composites Industry. Mechemco is a leading manufacturer of Unsaturated Polyester Resins, Vinyl Ester Resins, Gel Coats and Adhesives in India with a strong international presence in western countries with the brand name of Mechster®, Mechster™Plus, MechAdd™. Mechemco's Executives form a very well-knit family of highly talented, professionally qualified experienced individuals working in synergy to coordinate and optimally run the operations of Manufacturing, R & D, Technical Services, Distribution, as well as Applications Development.</p>

                        <p>Mechemco is proud of complete in-house development of all its resin products, offering over 200 different grades of Unsaturated Polyester Resins, Vinyl Ester Resins, Specialty Resins and Gel Coats, Adhesive Putty and Specialty Additives. Mechemco's products serve the entire spectrum of the composites and casting industry and to some extent coating and other industries. With a substantial share of our business coming from Exports, Mechemco has a strong international presence with customers located in North America, Europe, Africa, Middle East, South Asia & South-East Asia.</p>


               

            </div>
            <div className="col-xl-12 col-lg-5 col-md-12 col-sm-12 col-12 d-flex">

               
                       
                    <img src={require('../../assets/images/global_customer_map.png')} alt="" className="img-fluid" />
                  
            </div>

            <div className="col-xl-12 col-lg-5 col-md-12 col-sm-12 col-12 d-flex mt-50">
                <div className="abt-card card-light hp-card">
                    <div className="card-body p-sm-4">
                       
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 border-end ">
                                <h1 class="text-center">R&D</h1>
                                <p class="p-30">R & D and Technical Services Team of Mechemco are the backbone of the organization. The R & D team has always been successful in tailoring the resins to suit the specific needs of customers' end applications. There is also a continuous collaboration with India's Premier Institutes of Excellence, viz., ICT, Mumbai and Indian Institute of Technology for R & D as well as evaluation of parts and products to enhance the confidence and ensure quality of our products.</p>

                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <h1 class="text-center">Quality</h1>
                                <p class="p-30">R & D and Technical Services Team of Mechemco are the backbone of the organization. The R & D team has always been successful in tailoring the resins to suit the specific needs of customers' end applications. There is also a continuous collaboration with India's Premier Institutes of Excellence, viz., ICT, Mumbai and Indian Institute of Technology for R & D as well as evaluation of parts and products to enhance the confidence and ensure quality of our products.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
            <div className="row mt-50">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <h1 class="mb-30">Facilities</h1>
                                <p><b>Plant 1: Mechemco Resins Pvt Ltd, Addl. Patalganga (near Nhava Sheva Port)</b>: Commissioned in 2021, this site is equipped with state-of-the-art equipment and is strategically located just 60 km from Mumbai Port. The manufacturing capacity of this plant is approximately 25000 MTA of Unsaturated Polyester, Vinyl Ester Resins, Gelcoats, Flame Retardant Resins, Adhesives, etc.</p>

                                <p><b>Plant 2: Mechemco Industries, Dombivli:</b> Located approx 45 km from Mumbai City, the manufacturing capacity of this plant is approximately 7500 MTA of Unsaturated Polyester, Vinyl Ester Resins, Gelcoats, Flame Retardant Resins, Adhesives, etc.</p>

                                <p><b>Plant 3: Network Polymers Pvt Ltd, Tarapur, Maharashtra:</b> This plant was commissioned in 1993 and is located approx. 120 km from Mumbai City. This plant has a manufacturing capacity of approx. 10000 MTA of Unsaturated Polyester Resins, FR Resins & Gelcoats.</p>

                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
                            <img src={require('../../assets/images/facility.png')} alt="" className="img-fluid w-75 w-sm-100 float-xl-end float-start" />

                            </div>
            </div>

            <div className="row mt-50">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <h1 class="mb-30">Contributing to Composites Industry</h1>
                                <p>Mechemco has been actively participating in the activities of FRP Institute and All India Reinforced Plastics Manufacturers' Association (AIRPMA) to promote growth of Composites.  Mechemco has been supporting International Conference and Exhibition of Reinforced Plastics (ICERP) held biennially in India. Mechemco is also a founder member and chief coordinator of Polyester Resin Manufacturers' Association (PRMA). Mechemco is an active member of The Plastics Export Promotion Council (PLEXCONCIL) thus representing and promoting the Composites related activities of PLEXCONCIL.</p>

                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <img src={require('../../assets/images/industry.png')} alt="" className="img-fluid float-xl-end float-start w-75 w-sm-100" />

                            </div>
            </div>

        </div>
    
</section>
  )
}

export default AboutContentFull