
//page header images
export const ABOUT_HEAD_IMG = "about-head1.png";
export const DOWNLOAD_HEAD_IMG = "download-head.png";
export const PRODUCTS_HEAD_IMG = "product-head.png";
export const CAREER_HEAD_IMG = "about-head.png";
export const CONTACT_HEAD_IMG = "contact-head.png";
export const PDF_URL = "https://www.mechemco.com";

export const PRODUCT_URL = "https://api.mechemco.com/api/product";

export const CATEGORY_URL = "https://api.mechemco.com/api/product/getCategory";
export const CONTACT_URL = "https://api.mechemco.com/api/contact/saveContact";

export const LOGIN_URL = "https://api.mechemco.com/api/user/login";
export const REGISTER_URL = "https://api.mechemco.com/api/user/register";
export const SEND_EMAIL = "https://api.mechemco.com/api/sendemail/send-email";

export const REPORT_URL = "https://api.mechemco.com/api/user/report"; 