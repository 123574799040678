import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";


const Footer = () => {
    return (
        <footer>
            <div className="container">
                {/* newletter area start */}
                <div className="row d-none">
                    <div className="col-12">
                        <div className="card newsletter-card">
                            <div className="card-body">
                                <div className="row d-flex align-items-center">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <h3 className="sub-head">Newsletter</h3>
                                        <p>Sign up for the latest news and updates from the Mechemco Team</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <form className="nl-form" action="">
                                            <input type="email" name="" id="" placeholder="Email-ID" className="form-control" />
                                            <button type="submit" className="btn btn-theme btn-white">Sign Up</button> 
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* newletter area end */}

                {/* footer social media start */}
                <div className="row fs-area">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 fl-area">
                        <img src={require('../../assets/images/mechemco-logo-white.png')} alt="" className="footer-logo" />
                        {/* <p>We are a technology driven group with a mission of developing and implementing new technologies.</p> */}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="social-icons">
                            <a href="https://in.linkedin.com/company/mechemco-resins-pvt.-ltd." target="_blank"><FaLinkedinIn /></a>
                            {/* <a href="#"><FaInstagram /></a>
                            <a href="#"><FaXTwitter /></a>
                            <a href="#"><FaFacebookF /></a> */}
                        </div>
                    </div>
                </div>
                {/* footer social media end */}

                {/* copyright start */}
                <div className="row copyright-area">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-start">
                    <span>@2025 Mechemco</span>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                    <span>Powered by <a href="https://www.tecogis.com/" target="_blank">Tecogis</a></span>
                </div>
                </div>
                {/* copyright end */}

            </div>
        </footer>
    );
}



export default Footer;