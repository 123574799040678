import AboutContentFull from "../../components/about/AboutContentFull";
import AboutHeader from "../../components/about/AboutHeader";
import Timeline from "../../components/about/Timeline";



const AboutUs =() => {
    return(
        <div className="wrapper"> 
            <AboutHeader />
            <AboutContentFull />
           
            <Timeline />
        </div>
    );
}

export default AboutUs;